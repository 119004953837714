<template>
    <v-app app>
      <div class="wins">
        <section class="reviews">
          <div class="container">
            <div class="reviews-wrap">
              <div class="reviews-container">
                <div class="reviews-title">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Максим</v-list-item-title>
                      <v-list-item-subtitle class="mb-3">ст. 12.26.1</v-list-item-subtitle>
                      <div class="d-flex">
                        <img style="max-width: 200px" class="mr-4" src="../assets/reviews/6.jpg" alt="">
                        <p class="mt-6" style="float: left">
                          Никогда не думал, что сам столкнусь с лишением прав на своем опыте.
                          Слышал от знакомых, что кого –то лишают прав, думал ну как можно отказаться от
                          освидетельствования если ты трезв. Но вот и сам попал в ситуацию, когда ни с того
                          ни с сего отказался от прохождения медицинского освидетельствования и попал на лишение
                          прав. Ходил к юристам, закатывают огромные деньги, результата не обещают.
                          Решил попробовать сам, тем более что, перечитав кучу информации, понял,
                          что нарушения то все таки со стороны ДПС были. В процессе поиска нужной
                          информации наткнулся на онлайн-автоюрист . Цена смешная. Решил попробовать.
                          Спасибо ребятам за то, что они делают. Я получил гораздо больше помощи,
                          чем от походов к юристам, и в итоге выиграл свое дело сам.
                        </p>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="reviews-container">
                <div class="reviews-title">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Игорь</v-list-item-title>
                      <v-list-item-subtitle class="mb-3">г.Санкт-Петербург</v-list-item-subtitle>
                      <div class="d-flex">
                        <img style="max-width: 200px" class="mr-4" src="../assets/reviews/8.jpg" alt="">
                        <p>Много езжу, было любопытно послушать как оспаривать штрафы ГИБДД, так как бывает нарушаю то скорость, то правила парковки. Считаю, что не зря потратил 350 рублей. Получил много интересного, и самое главное теперь я знаю как не платить штрафы ГИБДД.</p>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="reviews-container">
                <div class="reviews-title">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Юрий Сергеевич</v-list-item-title>
                      <v-list-item-subtitle class="mb-3">ст. 12.8.1</v-list-item-subtitle>
                      <div class="d-flex">
                        <img style="max-width: 200px" class="mr-4" src="../assets/reviews/2.jpg" alt="">
                        <p>Суд лишил меня прав, для участия в суде привлекал юриста. Заплатил деньги, а адвокат даже не пришел в суд. Я пенсионер, и деньги для меня это были большие. Теперь юристам не верю. Для подачи жалобы прошел обучение. Все объяснили, доступно рассказали и я подготовил жалобу сам всего за 350 рублей.</p>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="reviews-container">
                <div class="reviews-title">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Михаил</v-list-item-title>
                      <v-list-item-subtitle class="mb-3">Москва</v-list-item-subtitle>
                      <div class="d-flex">
                        <img style="max-width: 200px" class="mr-4" src="../assets/reviews/3.jpg" alt="">
                        <p>Я корпоративный юрист, но по роду работы в грузоперевозках требуется повышение квалификации. Читать все в интернете оказалось сложно, долго и глупо. Я не ожидал такого высокого качества и проработки учебных часовых программ. Спасибо.</p>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="reviews-container">
                <div class="reviews-title">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Олег</v-list-item-title>
                      <v-list-item-subtitle class="mb-3">г.Пенза</v-list-item-subtitle>
                      <div class="d-flex">
                        <img style="max-width: 200px" class="mr-4" src="../assets/reviews/4.jpg" alt="">
                        <p>Купил автомобиль, который оказался в залоге, но брал в автосалоне, который после покупки даже разговаривать не захотел. После программы сам подготовил досудебную претензию за которую местные юристы просили 7000 рублей.</p>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="reviews-container">
                <div class="reviews-title">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Алексей</v-list-item-title>
                      <v-list-item-subtitle class="mb-3">г.Москва</v-list-item-subtitle>
                      <div class="d-flex">
                        <img style="max-width: 200px" class="mr-4" src="../assets/reviews/5.jpg" alt="">
                        <p>Была проблема с недоплатой. Предлагали выкупить дело, предлагали за 10000 услуги автоюриста. Я нашел программу и за 350 рублей сам во всем разобрался и получил полную сумму. Вы очень полезны, спс вам!</p>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="reviews-container">
                <div class="reviews-title">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Матвей</v-list-item-title>
                      <v-list-item-subtitle class="mb-3">г.Обнинск</v-list-item-subtitle>
                      <div class="d-flex">
                        <img style="max-width: 200px" class="mr-4" src="../assets/reviews/6.jpg" alt="">
                        <p>Полностью руководствуюсь принципом что хочешь сделать хорошо сделай сам. Я купил у вас все видеоуроки и понимаю, что решать любой вопрос я могу сам.</p>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="reviews-container">
                <div class="reviews-title">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Дмитрий</v-list-item-title>
                      <v-list-item-subtitle class="mb-3">г.Набережные Челны</v-list-item-subtitle>
                      <div class="d-flex">
                        <img style="max-width: 200px" class="mr-4" src="../assets/reviews/6.jpg" alt="">
                        <p>Посмотрел вебинар чтобы вернуть права в суде так все доступно и понятно и правда как вы пишете лучше сделать самому ведь ничего нет сложного.</p>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </v-app>
</template>

<script>
export default {
  name: 'Wins'
}
</script>

<style scoped lang="scss">

</style>
