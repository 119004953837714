<template>
  <div class="quest">
    <div @click="removePopup()" class="quest__close">
    </div>
    <div class="quest-name text-sm-h4 text-h5 text-md-h3 text-lg-h2 my-5 my-lg-10 text-center" style="max-width: 65%">
      {{ question ? question : 'Укажите телефон и почту, чтобы узнать результаты теста' }}
    </div>
    <div class="quest-wrap-btn mt-8">
      <v-radio
        color="#fff"
        v-for="button in buttons"
        :key="button"
        :label="button"
        :value="button"
        @click="result(question + ' : ' + button + '<br>')"
        dark
        class="my-3 text-sm-h5 text-h6 text-md-h4 text-lg-h4"
      ></v-radio>
    </div>
    <div v-if="1+1 === 3" class="btn-wrapper">
      <div
        v-for="button in buttons"
        :key="button"
        @click="result(question + ' : ' + button + '<br>')"
        class="quest__btn btn"
      >{{ button }}</div>
    </div>
    <div
      class="quest-images"
      v-if="isQuest === 3"
      style="width: 70%"
    >
      <v-row class="mb-5">
        <v-col
          v-for="n in images"
          :key="n"
          class="d-flex child-flex"
          cols="6"
          md="4"
          lg="3"
          xl="2"
          @click="result(question + ' : ' + n.name + '<br>')"
          style="cursor: pointer"
        >
          <v-img
            :src="require(`../assets/${n.img}`)"
            :lazy-src="require(`../assets/${n.img}`)"
            aspect-ratio="1"
            class="grey lighten-2"
            gradient="to top right, rgba(0,0,0,.33), rgba(55,55,55,.7)"
          >
            <p class="white--text text-center text-md-h4 ma-2">{{n.name}}</p>
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
    </div>
    <div
      class="form" v-if="isQuest === 5"
      style="width: 50%"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <v-form v-model="valid">
            <v-text-field
              v-model="form.city"
              label="Ваш город"
              :rules="nameRules"
              hide-details="auto"
              full-width
              color="#fff"
              dark
            ></v-text-field>
          </v-form>
        </v-col>
      </v-row>
      <div class="btn-wrapper">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              rounded
              color="yellow"
              class="black--text mt-10 float-right"
              width="150px"
              @click="valid ? result(form.city) : false"
            >
              Далее
            </v-btn>
          </template>
          <span>{{!valid?'Укажите город!':'Продолжить'}}</span>
        </v-tooltip>
      </div>
    </div>
    <div
      class="form" v-if="isQuest === 6"
      style="width: 50%"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <v-form v-model="validForm">
            <v-text-field
              v-model="form.email"
              label="Почта"
              :rules="emailRules"
              hide-details="auto"
              full-width
              color="#fff"
              dark
            ></v-text-field>
            <v-text-field
              v-model="form.phone"
              label="Телефон"
              :rules="phoneRules"
              hide-details="auto"
              full-width
              color="#fff"
              dark
            ></v-text-field>
          </v-form>
        </v-col>
      </v-row>
      <div class="btn-wrapper">
        <v-tooltip bottom v-if="!statusSend">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              rounded
              color="yellow"
              class="black--text mt-10 float-right"
              width="150px"
              @click="validForm ? result(form) : false"
            >
              Далее
            </v-btn>
          </template>
          <span>{{!validForm?'Заполните данные!':'Продолжить'}}</span>
        </v-tooltip>
        <v-btn
          v-if="statusSend"
          rounded
          color="yellow"
          class="black--text mt-10 float-right"
          width="150px"
          @click="validForm ? result(form) : false"
        >
          <v-progress-circular indeterminate></v-progress-circular>
        </v-btn>
      </div>
    </div>
    <div v-if="1+1===3" class="quest-name">Спасибо! Заявка принята, ожидайте звонка
      <p class="h3">Наши контакты:</p>
      <a href="tel:+79054324404">Телефон +7 (905) 432 44 04</a>
      <a href="">Адрес: г. Москва, Автозаводская 23а к2</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'quest',
  props: {
    question: String,
    buttons: Array,
    titleImages: Array,
    isQuest: Number,
    statusSend: Boolean
  },
  emits: ['removePopup', 'result', 'nextQuest', 'sendForm'],
  data () {
    return {
      form: {
        name: null,
        email: null,
        city: null,
        phone: null
      },
      images: [
        { name: 'Управление ТС в состоянии алкогольного опьянения', img: '1.jpg' },
        { name: 'Выезд на полосу встречного движения', img: '2.jpg' },
        { name: 'Повторное лишение водительских прав', img: '3.jpg' },
        { name: 'Пересечение  Ж/Д путей на запрещающий знак сигнала', img: '4.jpg' },
        { name: 'Оставление еста ДТП', img: '5.jpg' },
        { name: 'Причинение вреда здоровью при ДТП', img: '6.jpg' },
        { name: 'Управление ТС без номеров', img: '7.jpg' },
        { name: 'Проезд под кирпич', img: '8.jpg' },
        { name: 'Превышение скорости', img: '9.jpg' },
        { name: 'Проезд на красный', img: '10.jpg' },
        { name: 'Другое', img: '11.jpg' }
      ],
      nameRules: [
        v => !!v || 'Введите город',
        v => (v && v.length >= 4) || 'Не менее 4 символов',
        v => (v && v.length <= 10) || 'Не длиннее 10 символов'
      ],
      phoneRules: [
        value => !!value || 'Обязательное поле',
        value => (Boolean(Number(value))) || 'Введите только цифры',
        value => (value && value.length >= 11) || 'Не менее 11 цифр',
        value => (value && value.length <= 11) || 'Не более 11 цифр'
      ],
      emailRules: [
        v => !!v || 'E-mail обязательное поле',
        v => /.+@.+/.test(v) || 'E-mail введен не правильно'
      ],
      valid: false,
      validForm: false
    }
  },
  methods: {
    removePopup () {
      this.$emit('removePopup')
    },
    async result (button) {
      await this.$emit('result', button)
      this.$emit('nextQuest')
    }
  },
  mounted () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .v-input--selection-controls__input {
    input {
      opacity: 0;
    }
  }
  .quest {
    min-width: 100%;
    color: #ffffff!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    .quest-name {
    }
    .quest-wrap-btn {
      display: flex;
      flex-direction: column;
    }
    .input-wrap {
      width: 100%;
    }
}
</style>
