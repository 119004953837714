<template>
  <v-app app>
    <v-main>
      <Home />
      <Footer/>
    </v-main>
  </v-app>
</template>

<script>
import Home from './components/Home'
import Footer from './components/Footer'

export default {
  name: 'App',

  components: {
    Footer,
    Home
  },

  data: () => ({
    //
  })
}
</script>
<style lang="scss">
  @import "./assets/style.css";
  #app {
    position: relative;
    .container {
      padding: 0 100px;
      @media (max-width: 1200px) {
        padding: 0 50px
      }
      @media (max-width: 768px) {
        padding:5px 25px
      }
      @media (max-width: 420px) {
        padding: 5px 15px
      }
    }
  }
</style>
