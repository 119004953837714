var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"home-head d-flex justify-space-between pt-5"},[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}}),_c('div',[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Выигранные дела ")])]}}]),model:{value:(_vm.dialog.wins),callback:function ($$v) {_vm.$set(_vm.dialog, "wins", $$v)},expression:"dialog.wins"}},[_c('v-card',[_c('v-divider'),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog.wins = false}}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" Назад ")],1),_c('v-divider',{staticClass:"ml-4",attrs:{"vertical":""}}),_c('div',{staticClass:"title ml-4 my-8"},[_vm._v("ВЫИГРАННЫЕ ДЕЛА")])],1),_c('v-divider'),_c('Wins')],1)],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#fff","text":""},on:{"click":function($event){_vm.drawer = false}}},'v-btn',attrs,false),on),[_vm._v(" Решения и постановления судов ")])]}}]),model:{value:(_vm.dialog.ebsa),callback:function ($$v) {_vm.$set(_vm.dialog, "ebsa", $$v)},expression:"dialog.ebsa"}},[_c('v-card',[_c('v-toolbar',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog.ebsa = false}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v("Назад")]),_c('v-spacer'),_c('v-toolbar-title',{staticClass:"mr-lg-8 mr-md-6 mr-sm-4"},[_vm._v("УЗНАТЬ ШАНСЫ ПО СВОЕМУ ДЕЛУ")]),_c('v-spacer')],1),_c('v-divider'),_c('Ebsa')],1)],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#fff","text":""}},'v-btn',attrs,false),on),[_vm._v(" ДОСРОЧНЫЙ ВОЗВРАТ ПРАВ ")])]}}]),model:{value:(_vm.dialog.chance),callback:function ($$v) {_vm.$set(_vm.dialog, "chance", $$v)},expression:"dialog.chance"}},[_c('v-card',[_c('v-toolbar',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog.chance = false}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v("Назад")]),_c('v-spacer'),_c('v-toolbar-title',{staticClass:"mr-lg-8 mr-md-6 mr-sm-4"},[_vm._v("ДОСРОЧНЫЙ ВОЗВРАТ ПРАВ")]),_c('v-spacer')],1),_c('v-divider'),_c('Chance')],1)],1)],1)]),_c('div',{staticClass:"wrap d-flex flex-column align-center"},[_vm._m(0),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"black--text mt-10",attrs:{"rounded":"","color":"yellow","width":"150px"}},'v-btn',attrs,false),on),[_vm._v(" Начать тест ")])]}}]),model:{value:(_vm.dialog.test),callback:function ($$v) {_vm.$set(_vm.dialog, "test", $$v)},expression:"dialog.test"}},[_c('v-card',{staticClass:"test-wrap"},[_c('div',{staticClass:"test-wrap-progress d-flex align-center py-5 py-sm-7 py-md-10 py-lg-12"},[_c('div',{staticClass:"test-progress"},[_c('span',{style:({
                  left: ((_vm.progressValue -2) + "%")
                })},[_vm._v(" "+_vm._s(_vm.progressValue)+"% ")]),_c('v-progress-linear',{attrs:{"color":"white","value":_vm.progressValue}})],1),_c('v-btn',{staticClass:"ml-10",attrs:{"icon":"","text":"","color":"white"},on:{"click":function($event){_vm.dialog.test = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.isQuest === 1)?_c('quest',{attrs:{"is-quest":_vm.isQuest,"question":"В какой стадии дело?","buttons":[
                    'Вынесено постановление',
                    'В ожидании суда',
                    'Лишили прав',
                    'Повторное лишение прав'
                  ]},on:{"result":_vm.resultPush}}):_vm._e(),(_vm.isQuest === 2)?_c('quest',{attrs:{"is-quest":_vm.isQuest,"question":"Есть ли материалы дела","buttons":[
                    'Да, есть',
                    'Нет'
                  ]},on:{"result":_vm.resultPush}}):_vm._e(),(_vm.isQuest === 3)?_c('quest',{attrs:{"is-quest":_vm.isQuest,"question":"За что лишили прав?"},on:{"result":_vm.resultPush}}):_vm._e(),(_vm.isQuest === 4)?_c('quest',{attrs:{"is-quest":_vm.isQuest,"question":"Сколько времени прошло после лишения прав?","buttons":[
                    'Менее года',
                    'Более года',
                    'Еще не лишен'
                  ]},on:{"result":_vm.resultPush}}):_vm._e(),(_vm.isQuest === 5)?_c('quest',{attrs:{"is-quest":_vm.isQuest,"question":"Город или населенный пункт:"},on:{"result":_vm.city}}):_vm._e(),(_vm.isQuest === 6)?_c('quest',{attrs:{"is-quest":_vm.isQuest,"statusSend":_vm.statusSend},on:{"result":_vm.send}}):_vm._e()],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog.successSend),callback:function ($$v) {_vm.$set(_vm.dialog, "successSend", $$v)},expression:"dialog.successSend"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 mb-3 green lighten-2 white--text"},[_vm._v(" Заявка отправлена "),_c('v-icon',{staticClass:"ml-3",attrs:{"color":"white"}},[_vm._v("mdi-check-all")])],1),_c('v-card-text',[_c('p',{staticStyle:{"font-size":"15px"}},[_vm._v("Спасибо! Заявка принята, ожидайте звонка")]),_c('p',{},[_vm._v("Наши контакты:")]),_c('a',{attrs:{"href":"tel:+79054324404"}},[_vm._v("Телефон +7 (905) 432 44 04")]),_c('br'),_c('a',{attrs:{"href":""}},[_vm._v("Адрес: г. Москва, Автозаводская 23а к2")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green","text":""},on:{"click":function($event){_vm.dialog.successSend = false}}},[_vm._v(" Ок ")])],1)],1)],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap-title text-sm-h4 text-h5 text-md-h3 text-lg-h2"},[_vm._v(" Лишают водительских прав?"),_c('br'),_vm._v(" Вернём!"),_c('br'),_vm._v(" Узнайте как вернуть водительские права за 3 минуты!"),_c('br'),_vm._v(" Пройдите тест из 7 вопросов "),_c('span',{staticClass:"mt-5 mt-lg-10 d-block text-sm-h5 text-h6 text-md-h4 text-lg-h4"},[_vm._v("Вернем права досрочно!")])])}]

export { render, staticRenderFns }